import "../shared.scss"
import {CardSection} from "./common/Card";
import {Alert, Card} from "react-bootstrap";
import {Fragment, useState} from "react";
import {Result} from "../client/Result";
import {SubmitButton} from "./common/SubmitButton";

export interface Schedule {
    buildingName: string,
    buildingId: number,
    startTime: Date,
    endTime: Date
}

export interface EventScheduleResults {
    eventsToAutoScheduleByDayOfWeek: EventSchedule[]
}

export interface EventSchedule {
    day: string,
    event: Schedule[]
}

interface DailyScheduleProps {
    events: Schedule[]
}

export interface EventScheduleProps {
    eventSchedule: EventSchedule[]
    scheduleEvents: (events: Schedule[]) => Promise<Result<null>>
}

const groupBy = (items, key) => items.reduce(
    (result, item) => ({
        ...result,
        [item[key]]: [
            ...(result[item[key]] || []),
            item,
        ],
    }),
    {},
);

const DailySchedule = ({events}: DailyScheduleProps) => {
    const groupBy1: Map<string, Schedule> = groupBy(events, 'buildingName')
    const entries: [string, Schedule[]][] = Object.entries(groupBy1)

    const transform = entries.map(([buildingName, schedule], buildingIndex) => {
        return <Fragment key={`daily-schedule-${buildingName}`}>
            <h5 data-testid={`building-id-${buildingIndex}`}>{buildingName}</h5>
            <ul data-testid={`events-for-${buildingIndex}`}>
                {schedule.map((schedule, index) => {
                    const div = `${schedule.startTime.toLocaleString("en-GB")} to ${schedule.endTime.toLocaleString("en-GB")}`;
                    return <li key={`events-for-${buildingIndex}-event-${index}`}
                               data-testid={`events-for-${buildingIndex}-event-${index}`}>{div}</li>
                })}
            </ul>
        </Fragment>
    })

    return <>{transform}</>
}


export const EventSchedules = ({eventSchedule, scheduleEvents}: EventScheduleProps) => {
    const [allowSubmission, setAllowSubmission] = useState(true)
    const [result, setResult] = useState<Result<null>>()

    const handleSubmission = async () => {
        setAllowSubmission(false)
        const result = await scheduleEvents(eventSchedule.flatMap(it => it.event))
        setResult(result)
        setAllowSubmission(true)

    }

    function feedback (result?: Result<null>): JSX.Element {
        return result ? result.fold(
                () => <Alert variant='success'>Schedules submitted successfully</Alert>,
                (error) => <Alert variant='warning' data-testid={'feedback'}>{error}</Alert>)
            : <></>;
    }

    const map = eventSchedule.map((it, index) => {
            return <Fragment key={`even-schedule-card-${index}`}><Card.Title as={'h5'}
                                                                             data-testid='day'>{it.day}</Card.Title><DailySchedule
                events={it.event}/></Fragment>
        }
    );

    return <CardSection header={'Generate Schedules'}>
        {feedback(result)}
        <SubmitButton disabled={!allowSubmission} dataTestId={'create-event-schedule'} onSubmit={handleSubmission}>Schedule All
            Events</SubmitButton>
        {map}
    </CardSection>

}
