import { ConsumptionPage } from './components/pages/ConsumptionPage'
import {
    NotFoundPage,
} from './components/pages/NotFoundPage'
import { CalendarPage } from './components/pages/CalendarPage'
import { OnboardingPage } from './components/pages/OnboardingPage'
import { DataPage } from './components/pages/DataPage'
import { SchedulePage } from './components/pages/ScheduePage'
import { SingleBuildingStatusPage } from './components/pages/BuildingStatusPage'
import { DeviceConfigurationPage } from './components/pages/DeviceConfigurationPage'
import { DevicePreparationPage } from './components/pages/DevicePreperationPage'
import { LinkingPage } from './components/pages/LinkingPage'
import { DeviceInputsToMetersLinkingPage } from './components/pages/DeviceInputstoMeterLinkingPage'
import { Permission, } from './Permission'
import { HomePage } from './components/pages/HomePage'
import { UserAdminPage } from './components/pages/UserAdminPage'
import { EditUserAdminPage } from './components/pages/EditUserAdminPage'
import { CreateUserAdminPage } from "./components/pages/CreateUserAdminPage"
import { UserAlertFromLocation } from "./components/common/UserAlert"
import { AsyncBuildingSelector } from "./components/BuildingStatus"
import { FormClient } from "./client/FormClient"
import { DeviceClient } from "./client/DeviceClient"
import { BuildingClient } from "./client/BuildingClient"
import { EventScheduleClient } from "./client/EventScheduleClient"
import { DataClient } from "./client/DataClient"
import { UserClient } from "./client/UserClient"
import { CreateOrgAdminPage } from './components/pages/CreateOrgAdminPage'
import { EditOrgAdminPage } from "./components/pages/EditOrgAdminPage"
import { Entitlement } from './Entitlement'
import { RouteObject } from 'react-router-dom'
import { NotificationClient } from './client/NotificationsClient'
import { EditTariffPage } from "./components/pages/EditTariffPage"
import { CreateTariffPage } from './components/pages/CreateTariffPage'
import {EditControlStrategyPage} from "./components/pages/EditControlStrategyPage";
import {MeterClient} from "./client/MeterClient";
import {AddProgrammePage} from "./components/pages/AddProgrammePage";

export abstract class AppRoute {
    readonly path: string
    readonly readableName: string
    readonly permissions?: Permission[]

    protected constructor(path) {
        this.path = path
    }
}

interface WithParams<T> {
    withParams: (params: T) => string
}

export const buildingStatus: AppRoute & WithParams<number> = {
    readableName: 'Building Status',
    path: '/status/building/:id',
    permissions: [Permission.READ_BUILDING],
    withParams(params: number): string {
        return this.path.replace(':id', params.toString())
    }
}

export const deviceConfiguration: AppRoute & WithParams<string> = {
    readableName: 'Device Configuration',
    path: '/configuration/device/:id',
    permissions: [Permission.READ_DEVICE],
    withParams(params: string): string {
        return this.path.replace(':id', params)
    }
}

export const deviceInputsToMpansMapping: AppRoute = {
    readableName: 'Device Inputs to MPANs Mapping',
    path: '/linkDeviceInputsToMpans/',
    permissions: [Permission.READ_BUILDING]
}

export const deviceInputsToMpansMappingWithDeviceSelected: AppRoute & WithParams<string> = {
    readableName: 'Device Inputs to MPANs Mapping',
    path: '/device/:deviceId/linkDeviceInputsToMpans/',
    permissions: [Permission.READ_DEVICE],
    withParams(params: string): string {
        return this.path.replace(':deviceId', params)
    }
}

const onboard: AppRoute = {
    permissions: [Permission.WRITE_ONBOARDING],
    readableName: 'Onboard Site',
    path: '/onboard',
}
export const schedule: AppRoute = {
    permissions: [Permission.DISPLAY_SCHEDULES],
    readableName: 'Schedule Events',
    path: '/schedule'
}
export const admin: AppRoute = {
    permissions: [Permission.WRITE_USERS, Permission.WRITE_ORG],
    readableName: 'Admin',
    path: '/admin'
}
export const clientAdmin: AppRoute = {
    permissions: [Permission.WRITE_CLIENT_USERS],
    readableName: 'Admin',
    path: '/admin'
}
export const editUserAdmin: AppRoute & WithParams<string> = {
    permissions: [Permission.WRITE_USERS],
    readableName: 'Edit User Admin',
    path: '/edit/admin/:email',

    withParams(email: string): string {
        return this.path.replace(':email', email)
    }
}
export const editClientUserAdmin: AppRoute & WithParams<string> = {
    permissions: [Permission.WRITE_CLIENT_USERS],
    readableName: 'Edit User Admin',
    path: '/edit/admin/:email',

    withParams(email: string): string {
        return this.path.replace(':email', email)
    }
}

export const editControlStrategy: AppRoute & WithParams<[number, number]> = {
    permissions: [Permission.WRITE_ONBOARDING],
    readableName: 'Edit ControlStrategy',
    path: '/edit/building/:buildingId/controlStrategy/:controlStrategyId',

    withParams([buildingId, controlStrategyId]): string {
        return this.path.replace(':controlStrategyId', controlStrategyId).replace(':buildingId', buildingId)
    }
}

export const editTariff: AppRoute & WithParams<[number, number]> = {
    permissions: [Permission.WRITE_ONBOARDING],
    readableName: 'Edit Tariff',
    path: '/edit/building/:buildingId/tariff/:tariffId',

    withParams([buildingId, tariffId]): string {
        return this.path.replace(':tariffId', tariffId).replace(':buildingId', buildingId)
    }
}

export const createTariff: AppRoute & WithParams<number> = {
    permissions: [Permission.WRITE_ONBOARDING],
    readableName: 'Create Tariff',
    path: '/create/building/:buildingId/tariff',

    withParams(buildingId): string {
        return this.path.replace(':buildingId', buildingId)
    }
}

export const addProgramme: AppRoute & WithParams<number> = {
    permissions: [Permission.WRITE_ONBOARDING],
    readableName: 'Add Programme',
    path: '/create/building/:buildingId/programme',

    withParams(buildingId): string {
        return this.path.replace(':buildingId', buildingId)
    }
}

export const createUserAdmin: AppRoute = {
    permissions: [Permission.WRITE_USERS],
    readableName: 'Create User Admin',
    path: '/create/admin',
}
export const createClientUserAdmin: AppRoute = {
    permissions: [Permission.WRITE_CLIENT_USERS],
    readableName: 'Create User Admin',
    path: '/create/admin',
}

export const createOrgAdmin: AppRoute = {
    permissions: [Permission.WRITE_ORG],
    readableName: 'Create Org Admin',
    path: '/create/orgAdmin',
}
export const editOrgAdmin: AppRoute & WithParams<string> = {
    permissions: [Permission.WRITE_ORG],
    readableName: 'Edit Org Admin',
    path: '/edit/org/:orgId',

    withParams(orgId: string): string {
        return this.path.replace(':orgId', orgId)
    }
}

const prepare: AppRoute = {
    permissions: [Permission.WRITE_DEVICE],
    readableName: 'Commission Device',
    path: '/prepare'
}
const link: AppRoute = {
    permissions: [Permission.WRITE_DEVICE],
    readableName: 'Link Building',
    path: '/link'
}

const deviceLink: AppRoute = {
    permissions: [Permission.WRITE_DEVICE],
    readableName: 'Link MPAN',
    path: deviceInputsToMpansMapping.path
}

const install: AppRoute = {
    readableName: 'Device Status',
    permissions: [Permission.WRITE_DEVICE],
    path: '/install'
}
const home: AppRoute = {
    readableName: 'Home',
    path: '/'
}
const data: AppRoute = {
    permissions: [Permission.READ_READINGS],
    readableName: 'Data',
    path: '/data'
}
export const calendar: AppRoute = {
    permissions: [Permission.READ_EVENT, Permission.READ_UNAVAILABILITY, Permission.READ_BIDDING],
    readableName: 'Calendar',
    path: '/calendar'
}

export const metering: AppRoute = {
    permissions: [Permission.READ_READINGS],
    readableName: 'Metering',
    path: '/metering',
}

export const meteringOptionalRoute: AppRoute & WithParams<number> = {
    permissions: [Permission.READ_READINGS],
    readableName: 'Metering With Building Ids',
    path: ':buildingId?',

    withParams(buildingId: number): string {
        return metering.path +  '/' + this.path.replace(':buildingId?', buildingId)
    }
}

export const appRoutes: AppRoute[] = [
    onboard, schedule, prepare, link, install, deviceLink, data, metering, calendar, admin, clientAdmin
]


const notFound = (path: string): RouteObject => ({
    path,
    element: <NotFoundPage />
})

const createRouteElement = (entitlement: Entitlement, route: AppRoute, element?: JSX.Element, childRoutes?: RouteObject[]): RouteObject | null => 
   entitlement.can(route) ?  { path: route.path, element: element, children: childRoutes } : null

const handleNullChild =  (x : RouteObject | null) => x ? [x] : []

export const routes = (
    formClient: FormClient,
    deviceClient: DeviceClient,
    buildingClient: BuildingClient,
    meterClient: MeterClient,
    eventScheduleClient: EventScheduleClient,
    notificationClient: NotificationClient,
    dataClient: DataClient,
    userClient: UserClient,
    entitlement: Entitlement
): RouteObject[] => [
    {
        route: onboard,
        element: <OnboardingPage formClient={formClient} userClient={userClient} />,
    },
    {
        route: prepare,
        element: <DevicePreparationPage deviceClient={deviceClient} />
    },
    {
        route: link,
        element: <LinkingPage buildingClient={buildingClient} deviceClient={deviceClient} />
    },
    {
        route: deviceInputsToMpansMappingWithDeviceSelected,
        element: <DeviceInputsToMetersLinkingPage deviceClient={deviceClient} />
    },
    {
        route: deviceInputsToMpansMapping,
        element: <DeviceInputsToMetersLinkingPage deviceClient={deviceClient} />
    },
    {
        route: schedule,
        element: <SchedulePage eventScheduleClient={eventScheduleClient} />,
    },
    {
        route: admin,
        element: <UserAlertFromLocation><UserAdminPage userClient={userClient} /></UserAlertFromLocation>,
    },
    {
        route: clientAdmin,
        element: <UserAlertFromLocation><UserAdminPage userClient={userClient} /></UserAlertFromLocation>,
    },
    {
        route: editUserAdmin,
        element: <EditUserAdminPage userClient={userClient} />,
    },
    {
        route: editClientUserAdmin,
        element: <EditUserAdminPage userClient={userClient} />,
    },
    {
        route: createUserAdmin,
        element: <CreateUserAdminPage userClient={userClient} />,
    },
    {
        route: createClientUserAdmin,
        element: <CreateUserAdminPage userClient={userClient} />,
    },
    {
        route: createOrgAdmin,
        element: <CreateOrgAdminPage userClient={userClient} />,
    },
    {
        route: editOrgAdmin,
        element: <EditOrgAdminPage userClient={userClient} />,
    },
    {
        route: editControlStrategy,
        element: <EditControlStrategyPage buildingClient={buildingClient} />,
    },
    {
        route: editTariff,
        element: <EditTariffPage buildingClient={buildingClient} />,
    },
    {
        route: createTariff,
        element: <CreateTariffPage buildingClient={buildingClient} formClient={formClient}/>,
    },
    {
        route: addProgramme,
        element: <AddProgrammePage buildingClient={buildingClient}/>,
    },
    {
        route: install,
        element: <AsyncBuildingSelector retrieveMonitorData={(buildingId)=> buildingClient.retrieveMonitorData(buildingId)}
                                        scheduleEvent={(buildingId) => eventScheduleClient.scheduleTestEvent(buildingId)}
                                        buildingsSource={() => buildingClient.retrieveBuildings()} />
    },
    {
        route: buildingStatus,
        element: <SingleBuildingStatusPage buildingClient={buildingClient} eventScheduleClient={eventScheduleClient} />
    },
    {
        route: deviceConfiguration,
        element: <DeviceConfigurationPage deviceClient={deviceClient} />
    },
    {
        route: home,
        element: <HomePage redirectUrl={calendar.path} />
    },
    {
        route: data,
        element: <DataPage buildingClient={buildingClient} meterClient={meterClient} dataClient={dataClient} />
    },
    {
        route: metering,
        children: handleNullChild(createRouteElement(entitlement, meteringOptionalRoute, <ConsumptionPage buildingClient={buildingClient} dataClient={dataClient} eventClient={eventScheduleClient} /> ))
    },
    {
        route: calendar,
        element: <CalendarPage buildingClient={buildingClient} eventClient={eventScheduleClient} notificationClient={notificationClient} refreshTime={5000} />
    },
].flatMap(obj => {
    const element = createRouteElement(entitlement, obj.route, obj.element, obj?.children)
    return (element === null) ? [] : [element]
}).concat([notFound("*")])

