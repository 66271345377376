import {Select, SelectOption} from "./MuiSelect";
import {Timezone} from "../../time/Timezone";


interface TimeZoneSelectorProps {
    onChange: (timeZone: Timezone) => void
    options: Timezone[]
    initial: Timezone
    'data-testid'?: string
    id?: string
}


export const TimeZoneSelector = ({onChange, options, initial}: TimeZoneSelectorProps) => {
    const timeZoneOptions: SelectOption[] = Timezone.getUserFriendlyList(options)
    const onTimezoneChange = (e: SelectOption) => {
        const z = options.find(zone => zone.name == e.id)
        if (z) {
            onChange(z)
        }
    }


    return <Select initialValue={timeZoneOptions.find(z => z.id == initial.name)} data-testid={`select-timezone`}
                   label={"Time Zone"} onChange={onTimezoneChange} options={timeZoneOptions}></Select>
}