import {PropsWithChildren, useState} from "react";
import Button from "@mui/material/Button";
import {DownloadIcon} from "../icons/Icons";
import * as React from "react";

interface SubmitButtonProps extends PropsWithChildren {
    dataTestId?: string,
    disabled?: boolean
    onSubmit: (e) => Promise<void>,
    startIcon?: React.ReactNode
}

export const SubmitButton = ({disabled=false, onSubmit, dataTestId, children, startIcon}: SubmitButtonProps) => {
    const [disableSubmit, setDisableSubmit] = useState<boolean>(false)

    async function handleSubmit(event) {
        setDisableSubmit(true)
        await onSubmit(event)
        setDisableSubmit(false)
    }

    return <Button variant={'contained'} type="submit" data-testid={dataTestId}
                   startIcon={startIcon}
                   disabled={disabled || disableSubmit}
                   onClick={handleSubmit}>{children}</Button>
}

interface DownloadButtonProps extends SubmitButtonProps {
    text?: string
}

export const DownloadButton = ({text = 'Download', disabled = false, onSubmit, dataTestId,}: DownloadButtonProps) =>
    <SubmitButton onSubmit={onSubmit} disabled={disabled} dataTestId={dataTestId} startIcon={<DownloadIcon/>}>
        {text} </SubmitButton>

export const DownloadCSVButton = ({disabled = false, onSubmit, dataTestId,}: SubmitButtonProps) =>
    <DownloadButton text='Download CSV' disabled={disabled} onSubmit={onSubmit} dataTestId={dataTestId}/>
