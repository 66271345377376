import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {useEffect, useState} from "react"
import {Alert} from "react-bootstrap"
import {DateTimeRangePicker} from "../common/DateRangePicker";
import {SubmitButton} from "../common/SubmitButton";
import {BuildingInput, StatefulSelect} from "../BuildingInput";
import {Building} from "../BuildingStatus";
import {DateTime} from "../../time/DateTime";
import {
    CalendarEventCreationParameters,
    Constraint,
    DeviceCreateParameters,
    EventType,
    MfrrCreateParameters,
    UnavailabilityCreateParameters,
    WithEventId
} from "../../client/EventScheduleClient";
import {BanIcon, CrossIcon, TickIcon, TrashIcon} from "../icons/Icons";
import {EventStatus} from "../../model/EventStatus";
import {EventStatusBadge} from "./EventStatusBadge";
import {Duration} from "../common/Duration";
import {DeviceEvent} from "../DeviceEvent";
import {UnavailabilityEvent} from "../UnavailabilityEvent";
import {MfrrBiddingWindowEvent} from "../MfrrBiddingWindowEvent";
import {Failure, Result} from "../../client/Result";
import {useDateTimeContext} from "../../DateTimeContext";
import {getBuildingId} from "./Calendar";

export interface CalendarModalEvent {
    type: EventType
    startTime: DateTime
    endTime: DateTime
    parameters: CalendarEventCreationParameters
}

export type CalendarModalCreateEvent = CalendarModalEvent
export type CalendarModalUpdateEvent = CalendarModalEvent & WithEventId

export type CalendarModalExistingEvent = CalendarModalUpdateEvent & WithEventId & {
    title: string
    eventStatus: EventStatus
}

export interface CalendarModalProps {
    buildings: Building[]
    eventCreation: (event: CalendarModalCreateEvent) => Promise<Result<null>>
    retrieveValidationInfo: (event: CalendarModalCreateEvent) => Promise<Constraint | null>
    eventTypes: EventType[]
    initialStart: DateTime
    initialEnd: DateTime
    initialShow: boolean
}

export interface EditModalProps {
    buildings: Building[]
    initialEvent: CalendarModalExistingEvent
    retrieveValidationInfo: (event: CalendarModalUpdateEvent) => Promise<Constraint | null>
    eventUpdate: (event: CalendarModalUpdateEvent) => Promise<Result<null>>
    eventCancellation: (event: WithEventId) => Promise<Result<null>>
    eventTypes: EventType[]
    initialShow: boolean
}

export const EditEventModal = ({
                                   buildings,
                                   initialEvent,
                                   retrieveValidationInfo,
                                   eventUpdate,
                                   eventCancellation,
                                   eventTypes,
                                   initialShow,
                               }: EditModalProps) => {
    const [show, setShow] = useState<boolean>(initialShow)
    const [eventType, setEventType] = useState<EventType>(initialEvent.type)
    const [eventStart, setEventStart] = useState<DateTime>(initialEvent.startTime)
    const [eventEnd, setEventEnd] = useState<DateTime>(initialEvent.endTime)
    const [eventParameters, setEventParameters] = useState<CalendarEventCreationParameters>(initialEvent.parameters)

    const [constraint, setConstraint] = useState<Constraint | null>(null)

    const eventTypeOptions = eventTypes.filter(e => e.type == eventType.type).map(eventType => ({
        id: eventType.id(),
        name: eventType.displayName
    }))

    useEffect(() => {
        setEventStart(initialEvent.startTime)
        setEventEnd(initialEvent.endTime)
        initialShow && setShow(initialShow)
    }, [initialEvent.startTime, initialEvent.endTime])

    useEffect(() => {
        setEventType(initialEvent.type)
        setEventParameters(initialEvent.parameters)
    }, [initialEvent])

    useEffect(() => {
        setEventParameters(initialEvent.parameters)
    }, [initialEvent.parameters])

    useEffect(() => {
        if (eventType && eventStart.toMillis() < eventEnd.toMillis()) {
            retrieveValidationInfo({
                eventId: initialEvent.eventId,
                type: eventType,
                startTime: eventStart,
                endTime: eventEnd,
                parameters: eventParameters
            }).then(setConstraint)
        }
    }, [eventStart, eventEnd, eventType, eventParameters])

    // noinspection JSUnusedLocalSymbols
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dateRangeValid, setDateRangeValid] = useState(false)

    function handleModalSave(): Promise<Result<null>> {
        if (eventType && eventStart && eventEnd /*&& dateRangeValid*/) {
            return eventUpdate({
                eventId: initialEvent.eventId,
                type: eventType,
                startTime: eventStart,
                endTime: eventEnd,
                parameters: eventParameters,
            })
        } else {
            return Promise.resolve(new Failure('Missing parameters'))
        }
    }

    function title(prefix, eventTitle) {
        return eventTitle ? `${prefix} "${eventTitle}"` : prefix
    }

    return <EventModal setEventEnd={setEventEnd} setEventType={setEventType}
                       eventEnd={eventEnd} eventTypeOptions={eventTypeOptions}
                       show={show} setDateRangeValid={setDateRangeValid}
                       allowBuildingChange={false} setEventStart={setEventStart}
                       eventParameters={eventParameters} setEventParameters={setEventParameters}
                       setShow={setShow}
                       eventStart={eventStart} eventType={eventType} eventTypes={eventTypes}
                       handleModalSave={handleModalSave}
                       constraint={constraint}
                       handleEventCancellation={() => eventCancellation({eventId: initialEvent.eventId})}
                       eventStatus={initialEvent.eventStatus}
                       buildings={buildings}
                       title={title('Update Event', initialEvent.title)} showCancelEventButton/>
}


export const CreateEventModal = ({
                                     buildings,
                                     eventTypes,
                                     eventCreation,
                                     retrieveValidationInfo,
                                     initialStart,
                                     initialEnd,
                                     initialShow,
                                 }: CalendarModalProps) => {
    const eventTypeOptions = eventTypes.map(eventType => ({id: eventType.id(), name: eventType.displayName}))

    const [show, setShow] = useState<boolean>()
    const [eventType, setEventType] = useState<EventType>()
    const [eventStart, setEventStart] = useState<DateTime>(initialStart)
    const [eventParameters, setEventParameters] = useState<CalendarEventCreationParameters>()
    const [eventEnd, setEventEnd] = useState<DateTime>(initialEnd)
    const [selectableBuildings, setSelectableBuildings] = useState<Building[]>(buildings)

    const [constraint, setConstraint] = useState<Constraint | null>(null)

    useEffect(() => {
        setEventStart(initialStart)
        setEventEnd(initialEnd)
        setEventType(eventTypes.length == 1 ? eventTypes[0] : undefined)
        setEventParameters(undefined)
        initialShow && setShow(initialShow)
    }, [initialStart, initialEnd])

    function getEligibleBuildings(eventType?: EventType): Building[] {
        if(eventType)
            {
                return buildings.filter(b => {
                    switch (eventType.type) {
                        case "device":
                            return b.programmes?.includes(eventType.displayName) || eventType.displayName === 'OPS'
                        case "mfrr_bidding_window":
                            return b.programmes?.includes('mFRR')
                        case "unavailability":
                            return true
                    }
                })
            }

        return buildings
    }

    useEffect(() => {
        setEventParameters(undefined)
        setConstraint(null)
        setSelectableBuildings(getEligibleBuildings(eventType))
    }, [eventType])

    useEffect(() => {
        if (eventType && eventParameters && getBuildingId(eventType.type, eventParameters) && eventStart.toMillis() < eventEnd.toMillis()) {
            retrieveValidationInfo({
                type: eventType,
                startTime: eventStart,
                endTime: eventEnd,
                parameters: eventParameters
            }).then(setConstraint)
        }
    }, [eventStart, eventEnd, eventParameters])

    // noinspection JSUnusedLocalSymbols
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dateRangeValid, setDateRangeValid] = useState(false)

    function handleModalSave(): Promise<Result<null>> {
        if (eventType && eventParameters && eventStart && eventEnd /*&& dateRangeValid*/) {
            return eventCreation({
                type: eventType,
                startTime: eventStart,
                endTime: eventEnd,
                parameters: eventParameters
            })
        } else {
            return Promise.resolve(new Failure('Missing parameters'))
        }
    }

    return <EventModal setEventEnd={setEventEnd} setEventType={setEventType} eventType={eventType}
                       eventTypes={eventTypes}
                       eventEnd={eventEnd} eventTypeOptions={eventTypeOptions}
                       show={show} setDateRangeValid={setDateRangeValid} allowBuildingChange={true}
                       setEventStart={setEventStart} eventParameters={eventParameters}
                       setEventParameters={setEventParameters}
                       setShow={setShow}
                       eventStart={eventStart}
                       handleModalSave={handleModalSave}
                       constraint={constraint}
                       buildings={selectableBuildings}
                       title='Create Event'
    />
}


interface EventModalProps {
    show
    setShow
    buildings: Building[]
    allowBuildingChange: boolean
    setEventBuilding?: (Building) => void
    eventType?: EventType
    eventTypes: EventType[]
    setEventType
    eventTypeOptions
    eventStart: DateTime
    eventEnd: DateTime
    setEventParameters: (p: CalendarEventCreationParameters) => void
    eventParameters?: CalendarEventCreationParameters
    setEventEnd
    setEventStart
    setDateRangeValid
    handleModalSave: () => Promise<Result<null>>
    handleEventCancellation?: () => Promise<Result<null>>
    constraint: Constraint | null
    eventStatus?: EventStatus
    title: string
    showCancelEventButton?: boolean
}

const EventModal = ({
                        show,
                        setShow, buildings, allowBuildingChange,
                        eventType, eventTypes, setEventType,
                        eventTypeOptions, eventStart, eventEnd,
                        setEventEnd, setEventStart,
                        eventParameters, setEventParameters,
                        setDateRangeValid,
                        handleModalSave,
                        handleEventCancellation,
                        constraint,
                        eventStatus,
                        title,
                        showCancelEventButton = false
                    }: EventModalProps) => {
    const now: () => DateTime = useDateTimeContext()

    const [errorMessage, setErrorMessage] = useState<string>()

    useEffect(() => {
        setErrorMessage(undefined)
    }, [show])

    function eventHasEnded(): boolean {
        return eventEnd.toMillis() < now().toMillis()
    }

    async function handleSubmission() {
        setErrorMessage(undefined)
        const result: Result<null> = await handleModalSave()

        switch (result.__typename) {
            case "success": {
                setShow(false)
                break
            }
            case "failure": {
                setErrorMessage(`Failed to save event: ${(result as Failure).error}`)
                break
            }
            default: {
                setErrorMessage(`Unexpected result, please report to #ask-tech`)
                break
            }
        }
    }

    async function handleCancellation() {
        setErrorMessage(undefined)
        const result = await handleEventCancellation!()
        result ?
            result.fold(
                () => setShow(false),
                (error) => setErrorMessage(`Failed to delete the event: ${error}`)
            ) : setErrorMessage(`Unexpected result, please report to #ask-tech`)
    }

    return <Modal show={show} onHide={() => setShow(false)} data-testid={'eventModal'}>
        <Modal.Header>
            <Modal.Title data-testid={'modalTitle'}>{title}</Modal.Title>
            {eventStatus && <EventStatusBadge eventStatus={eventStatus}/>}
        </Modal.Header>

        <Modal.Body>
            <DateTimeRangePicker start={eventStart} end={eventEnd}
                                 onEndChange={e => setEventEnd(e)}
                                 onStartChange={s => setEventStart(s)}
                                 setErrorMessage={error => setErrorMessage(error)}
                                 reportIsValid={valid => setDateRangeValid(valid)}
                                 disabled={eventHasEnded()}
            />
            <Duration start={eventStart} end={eventEnd}/>
            <StatefulSelect className={'mb-3'} displayName='Event Type' testId='eventType'
                            selected={eventType ? {id: eventType.id(), name: eventType.displayName} : undefined}
                            onChange={(e) => setEventType(eventTypes.find(et => et.id() === e.id))}
                            options={eventTypeOptions}
                            disabled={eventHasEnded() || (eventTypeOptions.length == 1 && eventType?.id() === eventTypeOptions[0].id)}/>
            {eventType?.type === 'device' &&
                <DeviceEvent
                    setParameters={setEventParameters}
                    parameters={eventParameters as DeviceCreateParameters}
                    buildings={buildings}
                    constraint={constraint}
                    disabled={eventHasEnded()}
                    allowBuildingChange={allowBuildingChange}
                    eventType={eventType.displayName}
                />}
            {eventType?.type === 'unavailability' &&
                <UnavailabilityEvent
                    setParameters={setEventParameters}
                    parameters={eventParameters as UnavailabilityCreateParameters}
                    buildings={buildings}
                    disabled={eventHasEnded()}
                    allowBuildingChange={allowBuildingChange}
                />}
            {eventType?.type === 'mfrr_bidding_window' &&
                <MfrrBiddingWindowEvent
                    parameters={eventParameters as MfrrCreateParameters ?? {
                        bidPriceInEurosPerMegawattHours: 100.0,
                        bidQuantityInMegawatts: 1,
                        maxActivations: 1,
                        buildings: []
                    } }
                    setParameters={setEventParameters}
                    buildings={buildings}
                    disabled={eventHasEnded()}
                    allowBuildingChange={allowBuildingChange}
                />}
            {errorMessage &&
                <Alert data-testid='errorMessage' variant='danger'>{errorMessage}</Alert>}
        </Modal.Body>

        <Modal.Footer>
            <Button data-testid='closeButton' variant="outline-secondary"
                    onClick={() => setShow(false)}><CrossIcon/> Close</Button>
            {showCancelEventButton && !eventHasEnded() &&
                <Button data-testid='cancelEventButton' variant='outline-danger' onClick={handleCancellation}>
                    <TrashIcon/> Cancel Event</Button>}
            {!eventHasEnded() && <SubmitButton dataTestId='submitEventButton'
                                               disabled={!(eventParameters && eventType && getBuildingId(eventType.type, eventParameters) && eventStart && eventEnd /*&& dateRangeValid*/)}
                                               onSubmit={handleSubmission}><TickIcon/> Save changes</SubmitButton>}
        </Modal.Footer>
    </Modal>
}


interface CancelModalProps {
    show: boolean,
    setShow: (v: boolean) => void,
    eventStart: DateTime,
    eventEnd: DateTime
    building: Building,
    handleEventCancellationRequest: () => void
}

export const CancelEventModal = ({
                                     show,
                                     setShow,
                                     eventStart,
                                     eventEnd,
                                     building,
                                     handleEventCancellationRequest
                                 }: CancelModalProps) => {
    const [requestedCancellation, setRequestedCancellation] = useState<boolean>(false)

    async function handleCancellation() {

        handleEventCancellationRequest()
        setRequestedCancellation(true)

        if (requestedCancellation) {
            setShow(false)
        }
    }

    const hasRequested = <Modal.Body data-testid='request-sent-body' style={{lineHeight: "1.5"}}>
        The operations team has been notified of your request
        <br/>
        In case of an emergency please <a href="mailto:operations@oaktreepower.com" style={{
        verticalAlign: "baseline",
        color: "blue",
        textDecoration: "underline"
    }}>
        contact us
    </a>
    </Modal.Body>

    return <Modal show={show} onHide={() => setShow(false)} data-testid={'cancelModal'}>
        <Modal.Header>
            <Modal.Title data-testid={'modalTitle'}>Request Cancellation</Modal.Title>
        </Modal.Header>
        {requestedCancellation ? hasRequested
            :
            <>
                <Modal.Body>
                    <BuildingInput className={'mb-3'} disabled={true} buildings={[building]}
                                   onBuildingChange={() => undefined}
                                   building={building}/>
                    <DateTimeRangePicker start={eventStart} end={eventEnd}
                                         onEndChange={() => undefined}
                                         onStartChange={() => undefined}
                                         setErrorMessage={() => undefined}
                                         reportIsValid={() => false}
                                         disabled={true}
                    />
                </Modal.Body>
            </>
        }
        <Modal.Footer>
            <Button data-testid='closeButton' variant="outline-secondary"
                    onClick={() => setShow(false)}><CrossIcon/> Close</Button>
            {!requestedCancellation &&
                <Button data-testid='requestCancellationButton' variant='outline-danger' onClick={handleCancellation}>
                    <BanIcon/> Request Cancellation</Button>
            }
        </Modal.Footer>
    </Modal>
}
