import {Building, BuildingWithMpans, MonitorData} from "../components/BuildingStatus";
import {BaseClient} from "./BaseClient";
import {Failure, Result, Success} from "./Result";
import {DateTime, TimeOfDay} from "../time/DateTime";
import {Branded} from "../utils/BrandedTypes";

export type TransmissionFeeType = Branded<string, "TransmissionFeeType">

export function TransmissionFeeType(feeType: string){
    return feeType as TransmissionFeeType
}

export interface BuildingWithDeviceIds {
    id: number,
    name: string,
    deviceIds: string[],
}

interface TariffApi {
    id: number,
    transmissionFeeType: string,
    mpan: string,
    supplierName: string,
    startDate: string,
    endDate: string,
    pricePerKWh: number
}

function to(tariff: TariffApi): Tariff{
    return {
        ...tariff,
        transmissionFeeType: TransmissionFeeType(tariff.transmissionFeeType),
        startDate: DateTime.fromISO(tariff.startDate),
        endDate: DateTime.fromISO(tariff.endDate)
    }
}

export interface Tariff {
    id: number,
    transmissionFeeType: TransmissionFeeType,
    mpan: string,
    supplierName: string,
    startDate: DateTime,
    endDate: DateTime,
    pricePerKWh: number
}

export interface Programme{
    name: string
}


export interface ControlStrategy {
    id: number,
    bmsStrategyId: number,
    maxTotalDurationMinutes: number,
    maxRunDuration: number,
    maxNumEvents: number,
    saving: number,
    recoveryDuration: number,
    rampUpDownTime: number,
    timeZone: string,
    avoidExtremeWeather: boolean,
    minRunDurationMinutes: number,
    operatingHoursStart: TimeOfDay,
    operatingHoursEnd: TimeOfDay,
}

function toControlStrategy(controlStrategy: ControlStrategyApi): ControlStrategy{
    return {
         id: controlStrategy.id,
        bmsStrategyId: controlStrategy.bmsStrategyId,
        maxTotalDurationMinutes: controlStrategy.maxTotalDurationMinutes,
        maxRunDuration: controlStrategy.maxRunDuration,
        maxNumEvents: controlStrategy.maxNumEvents,
        saving: controlStrategy.saving,
        recoveryDuration: controlStrategy.recoveryDuration,
        rampUpDownTime: controlStrategy.rampUpDownTime,
        timeZone: controlStrategy.timeZone,
        avoidExtremeWeather: controlStrategy.avoidExtremeWeather,
        minRunDurationMinutes: controlStrategy.minRunDurationMinutes,
        operatingHoursStart: TimeOfDay.fromString(controlStrategy.operatingHoursStart),
        operatingHoursEnd: TimeOfDay.fromString(controlStrategy.operatingHoursEnd)
    }
}
function toControlStrategyApi(controlStrategy: ControlStrategy): ControlStrategyApi{
    return {
        id: controlStrategy.id,
        bmsStrategyId: controlStrategy.bmsStrategyId,
        maxTotalDurationMinutes: controlStrategy.maxTotalDurationMinutes,
        maxRunDuration: controlStrategy.maxRunDuration,
        maxNumEvents: controlStrategy.maxNumEvents,
        saving: controlStrategy.saving,
        recoveryDuration: controlStrategy.recoveryDuration,
        rampUpDownTime: controlStrategy.rampUpDownTime,
        timeZone: controlStrategy.timeZone,
        avoidExtremeWeather: controlStrategy.avoidExtremeWeather,
        operatingHoursStart: controlStrategy.operatingHoursStart.value,
        operatingHoursEnd: controlStrategy.operatingHoursEnd.value,
        minRunDurationMinutes: controlStrategy.minRunDurationMinutes
    }
}

interface ControlStrategyApi {
    id: number,
    bmsStrategyId: number,
    maxTotalDurationMinutes: number,
    maxRunDuration: number,
    maxNumEvents: number,
    saving: number,
    recoveryDuration: number,
    rampUpDownTime: number,
    operatingHoursStart: string,
    operatingHoursEnd: string,
    minRunDurationMinutes: number,
    timeZone: string,
    avoidExtremeWeather: boolean,
}

export interface UpdateTariffRequest {
    transmissionFeeType: string
    startDate: string
    endDate: string
    pricePerKWh: number
    mpan: string
}

export interface CreateTariffRequest {
    transmissionFeeType: TransmissionFeeType
    startDate: string
    endDate: string
    pricePerKWh: number
    mpan: string
    supplierId: number
}

export class BuildingClient {
    baseClient: BaseClient

    constructor(baseClient: BaseClient) {
        this.baseClient = baseClient
    }

    private async internalRetrieveBuildings<T>(): Promise<T> {
        return this.baseClient.getOk(`/api/buildings`);
    }

    async buildingData(buildingId: number): Promise<BuildingWithMpans> {
        return await this.baseClient.getOk<BuildingWithMpans>(`/api/building/${buildingId}`)
    }

    async retrieveBuildings(): Promise<Building[]> {
        const buildings = await this.retrieveBuildingsWithMeterMpans()
        return buildings.map(b => ({id: b.id, name: b.name, programmes: b.programmes}))
    }

    retrieveBuildingsWithMeterMpans(): Promise<BuildingWithMpans[]> {
        return this.internalRetrieveBuildings()
    }

    retrieveBuildingsWithDeviceIds(): Promise<BuildingWithDeviceIds[]> {
        return this.internalRetrieveBuildings()
    }

    async retrieveMonitorData(buildingId: number): Promise<MonitorData> {
        return this.baseClient.getOk(`/api/monitoring/building/${buildingId}`);
    }

    async retrieveTariffs(buildingId: number): Promise<Result<Tariff[]>> {
        return (await this.baseClient.getOkP<TariffApi[]>(`/api/siteManagement/tariff?buildingId=${buildingId}`))
            .map(tariffs => tariffs.map(tariff => (to(tariff))))
    }

    async retrieveBuildingProgrammes(buildingId: number): Promise<Result<Programme[]>> {
        return (await this.baseClient.getOkP<Programme[]>(`/api/siteManagement/programmes?buildingId=${buildingId}`))
    }

    async retrieveAllProgrammes(): Promise<Result<Programme[]>> {
        return (await this.baseClient.getOkP<Programme[]>(`/api/siteManagement/programmes/all`))
    }

    async addProgrammeToBuilding(programme: Programme, buildingId: number) {
        return await (this.baseClient.postOk(`/api/siteManagement/programmes?buildingId=${buildingId}`, {name: programme.name}))
    }

    async retrieveBuildingStrategy(buildingId: number): Promise<Result<ControlStrategy>> {
        return (await this.baseClient.getOkP<ControlStrategyApi>(`/api/siteManagement/controlStrategy?buildingId=${buildingId}`)).map(toControlStrategy)
    }
    async updateBuildingStrategy(controlStrategy: ControlStrategy): Promise<Result<null>> {
        const resp = (await this.baseClient.put(`/api/siteManagement/controlStrategy/${controlStrategy.id}`, toControlStrategyApi(controlStrategy)))
        return resp.ok ? new Success(null) : new Failure(await resp.text())
    }

    async retrieveTariff(tariffId: string): Promise<Result<Tariff>> {
        const okP: Result<TariffApi> = await  this.baseClient.getOkP(`/api/siteManagement/tariff/${tariffId}`);
        return  okP.map(tariff => (to(tariff)))
    }

    async updateTariff(tariffId: string, body: UpdateTariffRequest): Promise<Result<null>> {
        const response = await this.baseClient.put(`/api/siteManagement/tariff/${tariffId}`, body)
        return response.ok ? new Success(null) : new Failure(await response.text())
    }

    async getFeeTariffTransmissionType(): Promise<Result<TransmissionFeeType[]>>{
        return this.baseClient.getOkP<TransmissionFeeType[]>(`/api/siteManagement/tariff/transmissionFeeType`);

    }

    async createTariff(body: CreateTariffRequest): Promise<Result<null>> {
        const response = await this.baseClient.post(`/api/siteManagement/tariff/`, body)
        return response.ok ? new Success(null) : new Failure(await response.text())
    }

}
