import { useParams } from "react-router-dom"
import {isSuccess, Result} from "../../client/Result"
import { useEntitlements } from "../../EntitlementProvider"
import { CrudForm, FieldProp, MultiFieldProp } from "../common/CrudForm"
import {BuildingClient, Programme} from "../../client/BuildingClient"
import { useEffect, useState } from "react"
import { SelectOption } from "../common/MuiSelect"
import { meteringOptionalRoute } from "../../Routes"

interface AddProgrammePageProps {
    buildingClient: BuildingClient
}

interface ProgrammeFrom {
    programme: SelectOption

}

export const  AddProgrammePage = ({ buildingClient}: AddProgrammePageProps) => {

    const { buildingId } = useParams()
    const [allProgrammes, setAllProgrammes] = useState<Programme[]>([])
    const [buildingProgrammes, setBuildingProgrammes] = useState<Programme[]>([])
    const [selectableProgrammes, setSelectableProgrammes] = useState<Programme[]>([])

    const entitlement = useEntitlements()

    useEffect(() => {
        async function extracted(buildingId: number) {
            const result = (await buildingClient.retrieveBuildingProgrammes(buildingId))
            const fetchedAllProgrammes = (await buildingClient.retrieveAllProgrammes())
            if(isSuccess(result)) {
                setBuildingProgrammes(result.value)
            }
            if(isSuccess(fetchedAllProgrammes)){
                setAllProgrammes(fetchedAllProgrammes.value)
            }
        }

        extracted(parseInt(buildingId!)).then()

    }, [buildingId, entitlement])

    useEffect(() => {
        setSelectableProgrammes(
            allProgrammes.filter(programme =>
                !buildingProgrammes.some(buildingProg => buildingProg.name === programme.name)
            )
        )
    }, [allProgrammes, buildingProgrammes])

    function handleSubmit(tariffForm: Partial<ProgrammeFrom>): Promise<Result<null>> {
        const { programme } = tariffForm
        if (programme) {
            return buildingClient.addProgrammeToBuilding({name: programme.label}, parseInt(buildingId!))
        } else {
            throw new Error('Submit must have a programme value')
        }
    }

    const programme: (FieldProp<ProgrammeFrom> | MultiFieldProp<ProgrammeFrom>)[] = [
        { key: `programme`, label: 'Programme', type: 'select', options:
                selectableProgrammes?.map((programmes) => (
                    { id: programmes.name, label: programmes.name })), required: true },
    ]

    return allProgrammes.length > 0 && buildingProgrammes ?
        <CrudForm redirectPath={meteringOptionalRoute.withParams(Number(buildingId))} fields={programme} onSubmit={handleSubmit} /> : <></>

}
