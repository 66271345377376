import { useParams } from "react-router-dom";
import { Failure } from "../../client/Result";
import { useEntitlements } from "../../EntitlementProvider";
import { CrudForm } from "../common/CrudForm";
import { meteringOptionalRoute } from "../../Routes";
import {BuildingClient, ControlStrategy} from "../../client/BuildingClient";
import { useEffect, useState } from "react";
import {SelectOption} from "../common/MuiSelect";
import {Timezone} from "../../time/Timezone";

interface ControlStrategyFormModel extends Omit<ControlStrategy, 'timeZone'> {
    timeZone: SelectOption
}

function toFormModel(strategy: ControlStrategy): ControlStrategyFormModel {
    const timeZoneOption = Timezone.getUserFriendlyList(Timezone.getAll())
        .find(tz => tz.id === strategy.timeZone)

    if (!timeZoneOption) {
        throw new Error(`Invalid timezone: ${strategy.timeZone}`)
    }

    return {
        ...strategy,
        timeZone: timeZoneOption
    }
}
interface EditControlStrategyPageProps {
    buildingClient: BuildingClient
}

export const EditControlStrategyPage = ({ buildingClient }: EditControlStrategyPageProps) => {
    const { buildingId, controlStrategyId } = useParams()
    const [controlStrategy, setControlStrategy] = useState<ControlStrategyFormModel>();

    const entitlement = useEntitlements()

    useEffect(() => {
        if (controlStrategyId) {
            buildingClient.retrieveBuildingStrategy(Number(buildingId))
                .then(result => result.fold(
                    strategy => setControlStrategy(toFormModel(strategy)),
                    () => undefined
                ))
        } else {
            setControlStrategy(undefined)
        }
    }, [controlStrategyId, entitlement])

    const onSubmit = ({
        maxTotalDurationMinutes,
        maxRunDuration,
        maxNumEvents,
        saving,
        recoveryDuration,
        rampUpDownTime,
        operatingHoursStart,
        operatingHoursEnd,
        timeZone,
        avoidExtremeWeather,
        minRunDurationMinutes
    }: ControlStrategyFormModel) => {
        if(controlStrategy){
            const updatedBody = {
                id: controlStrategy.id,
                bmsStrategyId: controlStrategy.bmsStrategyId,
                maxTotalDurationMinutes: Number(maxTotalDurationMinutes),
                maxRunDuration: Number(maxRunDuration),
                maxNumEvents: Number(maxNumEvents),
                saving: Number(saving),
                recoveryDuration: Number(recoveryDuration),
                rampUpDownTime: Number(rampUpDownTime),
                operatingHoursStart,
                operatingHoursEnd,
                timeZone: timeZone.id,
                avoidExtremeWeather,
                minRunDurationMinutes: Number(minRunDurationMinutes)
            }
            return buildingClient.updateBuildingStrategy(updatedBody)
        } else {
            return Promise.reject(new Failure("Strategy does not exist"))
        }
    }

    function timeZonesAsOptions(): SelectOption[] {
        return Timezone.getUserFriendlyList(Timezone.getAll())
    }

    return controlStrategy && buildingId ?
        <CrudForm 
            redirectPath={meteringOptionalRoute.withParams(Number(buildingId))} 
            data={controlStrategy}
            fields={[
                { key: `maxTotalDurationMinutes`, label: 'Max Total Duration(m)', type: 'number', required: true },
                { key: `minRunDurationMinutes`, label: 'Min Run Duration(m)', type: 'number', required: true },
                { key: `maxRunDuration`, label: 'Max Event Duration(m)', type: 'number', required: true },
                { key: `maxNumEvents`, label: 'Max Events (daily)', type: 'number', required: true },
                { key: `saving`, label: 'Total Power Saving (Kw)', type: 'number', required: true },
                { key: `recoveryDuration`, label: 'Minimum Recovery(m)', type: 'number', required: true },
                { key: `rampUpDownTime`, label: 'Ramp Up Time(m)', type: 'number', required: true },
                { key: `avoidExtremeWeather`, label: 'Avoid Extreme Weather', type: 'boolean', required: true },
                { key: `timeZone`, label: 'TimeZone', type: 'select', options:timeZonesAsOptions() , required: true },
                { key: `operatingHoursStart`, label: 'Start of Operating Hours', type: 'timeOfDay', required: true },
                { key: `operatingHoursEnd`, label: 'End of Operating Hours', type: 'timeOfDay', required: true },
            ]} 
            onSubmit={onSubmit} 
        /> : <></>
}
