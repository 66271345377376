import FormControlLabel from '@mui/material/FormControlLabel';
import React from "react";
import {Switch} from "@mui/material";

interface BooleanSwitchProps {
    value?: boolean;
    label: string;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
    required?: boolean;
}

export const BooleanSwitch = ({ 
    value = false, 
    label, 
    onChange, 
    disabled = false, 
}: BooleanSwitchProps) => (
    <FormControlLabel
        labelPlacement="top"
        control={
            <Switch
                checked={Boolean(value)}
                onChange={(e) => onChange(e.target.checked)}
                disabled={disabled}
            />
        }
        label={label}
    />
);
